<template>
    <div>
      <!--================================
      =            Page Title            =
      =================================-->
  
      <section class="section page-title">
          <div class="container">
              <div class="row">
                  <div class="col-sm-8 m-auto">
                      <h1>Berita Terbaru</h1>
                  </div>
              </div>
          </div>
      </section>
  
      <!--====  End of Featured Article  ====-->
      <section class="post-grid section pt-0">
          <div class="container">
              <div class="row">
                  <div class="col-lg-4 col-md-6"  v-for="data in list_data" :key="data.id">
                      <article class="post-sm">
                          <div class="post-thumb">
                              <img class="w-100" :src="file_path+data.file" style="height: 350px">
                          </div>
                          <div class="post-meta">
                              <ul class="list-inline post-tag">
                                  <li class="list-inline-item">
                                      <img src="icon3.png">
                                  </li>
                                  <li class="list-inline-item">
                                      <a href="#">{{data.nama}}</a>
                                  </li>
                                  <li class="list-inline-item">
                                      {{UMUM.tglConvert(data.createAt)}}
                                  </li>
                              </ul>
                          </div>
                          <div class="post-title">
                              <h3><a @click="pushData(data.id)" href="javascript:void(0);">{{data.judul}}</a></h3>
                          </div>
                          <div class="post-details">
                              <p>{{data.gaji}}</p>
                          </div>
                      </article>
                  </div>
                  <div class="col-12">
                      <div class="text-center">
                          <button @click="btn_prev" type="button" class="btn" id="pref">
                          <i class="ti-angle-left" aria-hidden="true" style="font-size:100%"></i>
                          </button>
                          &nbsp;
                          <span style="font-size: 85%; color: #a5a5a5">
                              <b>{{page_first}} - {{page_last}}</b>
                          </span>
                          &nbsp;
                          <button @click="btn_next" type="button" class="btn" id="next">
                          <i class="ti-angle-right" aria-hidden="true" style="font-size:100%"></i>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </div>
  </template>
  
  <script>
    import UMUM from '../../library/umum'
  
      export default {
  
      components: {
          
      },
  
          data() {
              return {
                  jmlSekolah : '',
                  jmlSiswa : '',
                  jmlRombel : '',
  
          cari_value: "",
          page_first: 1,
          page_last: 0,
          page_limit : 9,
                  
          list_data : [],
          file_old : "",
          file_path: this.$store.state.url.URL_APP + "uploads/",
  
          UMUM: UMUM,
              }
          },
  
  
          methods: {
  
        getView: function() {
          fetch(this.$store.state.url.URL_BERITA + "view", {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({
                  data_ke: this.page_first,
                  cari_value: this.cari_value,
                  page_limit: this.page_limit,
              })
          })
              .then(res => res.json())
              .then(res_data => {
                  this.list_data = res_data.data;
                  this.page_last = res_data.jml_data;
                  // console.log(res_data);
          });
        },
  
        cek_active : function(data){
            if (data == 0) {
                return 'active'
            }
        },
  
        pushData : function(id){
            this.$router.push('/isiBerita?id='+id);
        },
  
        btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
  
    },
          
  
  
      mounted() {
          this.getView();
      },
    }
  </script>